'use client';

import type { ComponentProps, ComponentPropsWithoutRef, FC } from 'react';
import { forwardRef } from 'react';
import { useMediaQuery } from 'usehooks-ts';
import { cn } from '~/utils/cn';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from './dialog';
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
} from './drawer';

const useIsDesktop = () => {
  return useMediaQuery('(min-width: 1024px)');
};

const useResponsiveComponent = <A, B>(
  desktopComponent: A,
  mobileComponent: B,
) => {
  return useIsDesktop() ? desktopComponent : mobileComponent;
};

export const ResponsiveDialog: FC<ComponentProps<typeof Drawer>> = (props) => {
  const Component = useResponsiveComponent(Dialog, Drawer);
  return <Component {...props} />;
};

export const ResponsiveDialogTrigger = forwardRef<
  HTMLButtonElement,
  ComponentProps<typeof DrawerTrigger>
>((props, ref) => {
  const Component = useResponsiveComponent(DialogTrigger, DrawerTrigger);
  return <Component ref={ref} {...props} />;
});
ResponsiveDialogTrigger.displayName = 'ResponsiveDialogTrigger';

export const ResponsiveDialogContent = forwardRef<
  HTMLDivElement,
  ComponentProps<typeof DrawerContent> & { withCloseButton?: boolean }
>((props, ref) => {
  const Component = useResponsiveComponent(DialogContent, DrawerContent);
  return <Component ref={ref} {...props} />;
});
ResponsiveDialogContent.displayName = 'ResponsiveDialogContent';

export const ResponsiveDialogHeader: FC<ComponentProps<typeof DrawerHeader>> = (
  props,
) => {
  const Component = useResponsiveComponent(DialogHeader, DrawerHeader);
  return <Component {...props} />;
};

export const ResponsiveDialogTitle = forwardRef<
  HTMLHeadingElement,
  ComponentProps<typeof DrawerTitle>
>((props, ref) => {
  const Component = useResponsiveComponent(DialogTitle, DrawerTitle);
  return <Component ref={ref} {...props} />;
});
ResponsiveDialogTitle.displayName = 'ResponsiveDialogTitle';

export const ResponsiveDialogDescription = forwardRef<
  HTMLParagraphElement,
  ComponentProps<typeof DrawerDescription>
>((props, ref) => {
  const Component = useResponsiveComponent(
    DialogDescription,
    DrawerDescription,
  );
  return <Component ref={ref} {...props} />;
});
ResponsiveDialogDescription.displayName = 'ResponsiveDialogDescription';

export const ResponsiveDialogBody: FC<ComponentPropsWithoutRef<'div'>> = ({
  className,
  ...restProps
}) => {
  const isDesktop = useIsDesktop();

  return (
    <div
      className={cn(
        'flex flex-col gap-4',
        {
          'px-4': !isDesktop,
        },
        className,
      )}
      {...restProps}
    />
  );
};

export const ResponsiveDialogFooter: FC<
  ComponentProps<typeof DrawerFooter>
> = ({ className, ...restProps }) => {
  const Component = useResponsiveComponent(DialogFooter, DrawerFooter);
  return (
    <Component
      className={cn(
        'flex flex-col gap-3',
        'lg:flex-row-reverse lg:justify-start',
        className,
      )}
      {...restProps}
    />
  );
};

export const ResponsiveDialogClose = forwardRef<
  HTMLButtonElement,
  ComponentProps<typeof DrawerClose>
>((props, ref) => {
  const Component = useResponsiveComponent(DialogClose, DrawerClose);
  return <Component ref={ref} {...props} />;
});
ResponsiveDialogClose.displayName = 'ResponsiveDialogClose';
